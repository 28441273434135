import { useTranslation } from '@patrianna/core-components'
import cx from 'classnames'

import { useAuth } from 'app/context/auth'
import { useCountryConfig } from 'app/context/country'
import RichText from 'components/RichText'
import ShopTerms from 'components/ShopTerms'
import LogoLink from 'containers/LogoLink'
import SocialButtons from 'containers/Menu/SocialButtons'
import useIsBottomTabsBar from 'hooks/useIsBottomTabsBar'
import { useAppSelector } from 'store/hooks'
import { shouldForceVerifyKYCSelector } from 'store/modules/user/selectors'

import classes from './styles.module.scss'

function Footer({ isFooterCentered, className }: { isFooterCentered?: boolean; className?: string }) {
  const t = useTranslation()
  const isBottomTabsBar = useIsBottomTabsBar()

  const { socialLinksFeature } = useCountryConfig()
  const { isScEnabled } = useAuth()
  const isForceVerifyKYC = useAppSelector(shouldForceVerifyKYCSelector)

  return (
    <footer className={classes.root}>
      <div
        className={cx(
          classes.footerContainer,
          'main-container limited',
          { [classes.centered]: isFooterCentered, [classes.extraPadding]: isBottomTabsBar },
          className
        )}
      >
        {!isForceVerifyKYC && (
          <>
            <div className={classes.footerLogoHolder}>
              <LogoLink className={classes.logo} />
              <p className={classes.text}>{t('footer.copyright')}</p>
            </div>
            <ShopTerms scEnabled={isScEnabled} />
            <div className={classes.footerMenuHolder}>
              <div className={classes.fmBlock}>
                <h4 className={classes.footerTitle}>{t('footer.about_menu_title')}</h4>
                <ul className={classes.footerMenu}>
                  <li className={classes.fmItem}>
                    <RichText field={t('footer.about_link')} />
                  </li>
                  {isScEnabled && process.env.ZENDESK_SUPPORT_LINK ? (
                    <li className={classes.fmItem}>
                      <RichText field={t('footer.customer_support_link')} />
                    </li>
                  ) : null}
                  <li className={classes.fmItem}>
                    <RichText field={t('footer.terms_of_service_link')} />
                  </li>
                  {isScEnabled && (
                    <li className={classes.fmItem}>
                      <RichText field={t('footer.sweepstakes_rules_link')} />
                    </li>
                  )}
                  <li className={classes.fmItem}>
                    <RichText field={t('footer.responsible_play_link')} />
                  </li>

                  <li className={classes.fmItem}>
                    <RichText field={t('footer.privacy_policy_link')} />
                  </li>
                </ul>
              </div>
              <div className={classes.fmBlock}>
                <RichText
                  field={t('footer.games_menu')}
                  components={{
                    heading4: ({ children }) => <h4 className={classes.footerTitle}>{children}</h4>,
                    list: ({ children }) => <ul className={classes.footerMenu}>{children}</ul>,
                    listItem: ({ children }) => <li className={classes.fmItem}>{children}</li>,
                  }}
                />
              </div>
            </div>
          </>
        )}
        {socialLinksFeature.enabled && (
          <>
            <div className={classes.footerMenuHolder}>
              <div className={classes.fmBlock}>
                <h4 className={classes.footerTitle}>{t('footer.follow_us')}</h4>
                <SocialButtons />
              </div>
            </div>
            <div className={classes.divider} />
            <div className={classes.textBlock}>
              <RichText
                field={t('footer.contact_us_link')}
                components={{ paragraph: ({ children }) => <h4 className={classes.footerTitle}>{children}</h4> }}
              />
              <div className={classes.telBlock}>
                <div className={cx(classes.telWrapper, classes.telOneLine)}>
                  <RichText
                    field={t('footer.country_tel_US')}
                    components={{ paragraph: ({ children }) => <p className={classes.text}>{children}</p> }}
                  />
                </div>
              </div>
              <p className={classes.text}>{t('footer.tel_caption')}</p>
              <RichText
                field={t('footer.partners_link')}
                components={{
                  paragraph: ({ children }) => <p className={classes.text}>{children}</p>,
                }}
              />
            </div>
          </>
        )}
        <div className={classes.divider} />
        <div className={classes.textBlock}>
          <RichText
            field={t('footer.owned_info')}
            components={{ paragraph: ({ children }) => <p className={classes.text}>{children}</p> }}
          />
          <p className={cx(classes.text, classes.textWhite)}>{t('footer.copyright')}</p>
        </div>
      </div>
    </footer>
  )
}

export default Footer
