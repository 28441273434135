import { trackEvent } from 'config/analytic'
import type { GAEventMenuAreaType, GAEventMenuModeType } from 'containers/Menu/types'
import { useAppSelector } from 'store/hooks'
import { sweepstakeEnabledSelector } from 'store/modules/appConfig/selectors'
import { isLoggedInSelector } from 'store/modules/user/selectors'

function useGetGAEventMenuParams() {
  const isLoggedIn = useAppSelector(isLoggedInSelector)
  const isScEnabled = useAppSelector(sweepstakeEnabledSelector)

  const getGAModeValue = (): GAEventMenuModeType => {
    if (!isLoggedIn) {
      return null
    }

    return isScEnabled ? 'SC' : 'GC'
  }

  const getGAAreaValue = (): GAEventMenuAreaType => {
    return isLoggedIn ? 'login' : 'guest'
  }

  const GAEventMenuParams = {
    category: getGAModeValue(),
    label: getGAAreaValue(),
  }

  const trackGAEventsInMenu = (currentUrl: string, targetUrl: string, event: string) => {
    if (currentUrl !== targetUrl) {
      trackEvent(event, {
        label: GAEventMenuParams.label,
        ...(GAEventMenuParams.category && { category: GAEventMenuParams.category }),
      })
    }
  }

  return { GAEventMenuParams, trackGAEventsInMenu }
}

export default useGetGAEventMenuParams
