import cx from 'classnames'

import { Link } from 'components/Link'
import ROUTES from 'temp/routes.json'

import classes from './styles.module.scss'

export type Props = {
  handleOnClick?: () => void
  className?: string
  href?: string
  isLogoSm?: boolean
}

export default function LogoLink({ handleOnClick, className, href, isLogoSm, ...props }: Props) {
  return (
    <Link href={href || ROUTES.INDEX} passHref data-test-='logo-link'>
      <img
        src={`${process.env.IMG_ORIGIN}/logo-${isLogoSm ? 'short' : 'long'}.png`}
        height={31}
        alt='SpinBlitz'
        onClick={handleOnClick && handleOnClick}
        className={cx(classes.logo, className)}
        {...props}
      />
    </Link>
  )
}
